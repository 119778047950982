import { createAction, createSlice } from '@reduxjs/toolkit'

import { initialStateOrdersList as initialState } from '../resources'

const secondaryDfaDealsListSlice = createSlice({
    name: 'secondaryDfaDealsList',
    initialState,
    reducers: {
        getSecondaryDfaDealsList: (state) => {
            return state
        },
        updateSecondaryDfaDealsList: (state, action) => {
            return { ...state, ...action.payload }
        },
        updateSecondaryDfaDealsById: (state, action) => {
            state.data[action.payload.secondaryDfa.id] = action.payload.secondaryDfa
        },
        dropSecondaryDfaDealsList: () => {
            return { ...initialState }
        },
        updateErrorSecondaryDfaDealsList: (state, action) => {
            state.error = action.payload
        },
        updateLoadingSecondaryDfaDealsList: (state, action) => {
            state.isLoading = action.payload
        },
    },
})

export const loadSecondaryDfaDeals = createAction('secondaryDfaList/loadSecondaryDfaDeals')
export const {
    updateSecondaryDfaDealsList,
    getSecondaryDfaDealsList,
    dropSecondaryDfaDealsList,
    updateSecondaryDfaDealsById,
    updateErrorSecondaryDfaDealsList,
    updateLoadingSecondaryDfaDealsList,
} = secondaryDfaDealsListSlice.actions
export default secondaryDfaDealsListSlice.reducer
