import {
    ISecondaryDfaDealsListState,
    ISecondaryDfaDetailsState,
    ISecondaryDfaOrdersListState,
} from './types'

export const initialState = {
    id: '0',
    order_type: 'buy',
    user_id: '123',
    asset_id: '39',
    status: 'accepted',
    created_at: 0,
    canceled_at: 0,
    executed_at: 0,
    original_amount_dfa: 0,
    rest_amount_dfa: 0,
    price_per_dfa: 0,
    total_price: 0,
    rest_price: 0,
    isLoading: false,
    error: '',
} as ISecondaryDfaDetailsState

export const initialStateOrdersList = {
    error: null,
    isLoading: false,
    data: [],
} as ISecondaryDfaOrdersListState

export const initialStateDealsList = {
    isLoading: false,
    data: [],
    error: '',
} as ISecondaryDfaDealsListState
