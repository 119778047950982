import { createAction, createSlice } from '@reduxjs/toolkit'

import { initialStateOrdersList as initialState } from '../resources'

const secondaryDfaListSlice = createSlice({
    name: 'secondaryDfaList',
    initialState,
    reducers: {
        getSecondaryDfaList: (state) => {
            return state
        },
        updateSecondaryDfaList: (state, action) => {
            return { ...state, ...action.payload }
        },
        updateSecondaryDfaById: (state, action) => {
            state.data[action.payload.secondaryDfa.id] = action.payload.secondaryDfa
        },
        addSecondaryDfaById: (state, action) => {
            state.data.push(action.payload)
        },
        removeSecondaryDfaById: (state, action) => {
            state.data = state.data.filter((v) => v.id !== action.payload)
        },
        dropSecondaryDfaList: () => {
            return { ...initialState }
        },
        updateErrorSecondaryDfaList: (state, action) => {
            state.error = action.payload
        },
        updateLoadingSecondaryDfaList: (state, action) => {
            state.isLoading = action.payload
        },
    },
})

export const loadSecondaryDfas = createAction('secondaryDfaList/loadSecondaryDfas')
export const loadSecondaryDfasByAssetId = createAction<string>(
    'secondaryDfaList/loadSecondaryDfasByAssetId',
)
export const {
    updateSecondaryDfaList,
    getSecondaryDfaList,
    dropSecondaryDfaList,
    updateSecondaryDfaById,
    updateErrorSecondaryDfaList,
    updateLoadingSecondaryDfaList,
    addSecondaryDfaById,
    removeSecondaryDfaById,
} = secondaryDfaListSlice.actions
export default secondaryDfaListSlice.reducer
