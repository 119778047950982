import { ISecondaryOrder, ISecondaryOrderBuyRequest, RevokeByOrderPayload } from '@dltru/dfa-models'
import { createAction, createSlice } from '@reduxjs/toolkit'

import { initialState } from '../resources'

const secondaryDfaDetailsSlice = createSlice({
    name: 'secondaryDfaDetails',
    initialState,
    reducers: {
        getSecondaryDfaDetails: (state) => {
            return state
        },
        updateSecondaryDfaDetails(state, action) {
            const data = action.payload
            return { ...state, ...data }
        },
        setSecondaryDfaError(state, action) {
            state.error = action.payload
        },
    },
})

export const postSecondaryBuyDfa = createAction<Partial<ISecondaryOrderBuyRequest> | undefined>(
    'secondaryDfaDetails/postSecondaryBuyDfa',
)

export const postSecondaryBuyDfaPrefly = createAction<Partial<ISecondaryOrder> | undefined>(
    'secondaryDfaDetails/postSecondaryBuyDfaPrefly',
)

export const postSecondarySellDfa = createAction<Partial<ISecondaryOrder> | undefined>(
    'secondaryDfaDetails/postSecondarySellDfa',
)

export const postSecondarySellDfaPrefly = createAction<Partial<ISecondaryOrder> | undefined>(
    'secondaryDfaDetails/postSecondarySellDfaPrefly',
)

export const postSecondaryBuyAcceptDfa = createAction<Partial<ISecondaryOrder> | undefined>(
    'secondaryDfaDetails/postSecondaryBuyAcceptDfa',
)

export const postSecondaryBuyAcceptDfaPrefly = createAction<Partial<ISecondaryOrder> | undefined>(
    'secondaryDfaDetails/postSecondaryBuyAcceptDfaPrefly',
)

export const postSecondarySellAcceptDfa = createAction<Partial<ISecondaryOrder> | undefined>(
    'secondaryDfaDetails/postSecondarySellAcceptDfa', 
)

export const postSecondarySellAcceptDfaPrefly = createAction<Partial<ISecondaryOrder> | undefined>(
    'secondaryDfaDetails/postSecondarySellAcceptDfaPrefly',
)

type RevokeActionType = {
    data: RevokeByOrderPayload
    withUpdateOrder?: boolean
}
export const revokeSecondaryBuyDfa = createAction<RevokeActionType>(
    'secondaryDfaDetails/revokeSecondaryBuyDfa',
)
export const revokeSecondarySellDfa = createAction<RevokeActionType>(
    'secondaryDfaDetails/revokeSecondarySellDfa',
)
export const { updateSecondaryDfaDetails, getSecondaryDfaDetails, setSecondaryDfaError } =
    secondaryDfaDetailsSlice.actions

export default secondaryDfaDetailsSlice.reducer
