import { StrictEffect } from 'redux-saga/effects'
import { call, put, takeLatest } from 'typed-redux-saga'

import api from '@services/api'

import { loadSecondaryDfaDeals, updateSecondaryDfaDealsList } from './index'

function* handleLoadSecondaryDfaDeals() {
    try {
        yield* put(updateSecondaryDfaDealsList({ error: '', isLoading: true }))
        const { data, error } = yield* call(api.lib.getSecondaryDfaDealsService)

        if (error) {
            throw error
        }

        yield* put(updateSecondaryDfaDealsList({ isLoading: false, data: data.items }))
    } catch (error) {
        console.log(error)
        yield* put(updateSecondaryDfaDealsList({ error, isLoading: false, data: [] }))
    }
}

export function* watchLoadSecondaryDfaDeals(): Generator<StrictEffect> {
    yield* takeLatest(loadSecondaryDfaDeals.type, handleLoadSecondaryDfaDeals)
}
