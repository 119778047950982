import { Saga } from '@redux-saga/types'
import { all, fork } from 'typed-redux-saga'

import * as authSagas from './auth/sagas'
import * as certificatesSagas from './certificates/sagas'
import * as DfaAvailableDeals from './dfa/availableDeals/sagas'
import * as dfaDealDetailSagas from './dfa/deal/sagas'
import * as dfaDealsListSagas from './dfa/deals/sagas'
import * as dfaDetailsSagas from './dfa/details/sagas'
import * as dfaListSagas from './dfa/list/sagas'
import * as dfaOwnersListSagas from './dfa/owners/sagas'
import * as dfaOwnershipTransfer from './dfa/ownershipTransfer/sagas'
import * as dfaRatesSagas from './dfa/rates/sagas'
import * as documentsSagas from './documents/sagas'
import * as earlyRepaymentSagas from './earlyRepayment/sagas'
import * as moneyAccountsSagas from './money/sagas'
import * as orderDetailsSagas from './orders/details/sagas'
import * as orderListSagas from './orders/list/sagas'
import * as transfersOrderSagas from './orders/transfer/sagas'
import * as paymentSchedulerSagas from './paymentsScheduler/sagas'
import * as profileDetailsSagas from './profiles/details/sagas'
import * as qualifyStatementSagas from './qualifyStatement/sagas'
import * as registerSagas from './register/sagas'
import * as roleFormsSagas from './roleForms/sagas'
import * as secondaryDfaDealsListSagas from './secondaryDfa/deals/sagas'
import * as secondaryDfaDetailsSagas from './secondaryDfa/details/sagas'
import * as secondaryDfaListSagas from './secondaryDfa/list/sagas'
import * as tariffSagas from './tariff/sagas'
import * as walletUserAssetsListSagas from './wallet/assets/sagas'
import * as walletEncumbranceSagas from './wallet/encumbrance/sagas'
import * as walletUserHistoryListSagas from './wallet/history/sagas'
import * as walletLocksSagas from './wallet/locks/sagas'
import * as taxOperationsSagas from './tax/operations/sagas'
import * as taxTaxesSagas from './tax/taxes/sagas'

function* sagas(): Generator<unknown, any, unknown> {
    const watchers = Object.values({
        ...authSagas,
        ...registerSagas,
        ...dfaListSagas,
        ...dfaDetailsSagas,
        ...dfaDealsListSagas,
        ...dfaOwnersListSagas,
        ...dfaOwnershipTransfer,
        ...orderDetailsSagas,
        ...orderListSagas,
        ...secondaryDfaListSagas,
        ...secondaryDfaDealsListSagas,
        ...secondaryDfaDetailsSagas,
        ...DfaAvailableDeals,
        ...dfaDealDetailSagas,
        ...profileDetailsSagas,
        ...roleFormsSagas,
        ...moneyAccountsSagas,
        ...walletUserAssetsListSagas,
        ...walletUserHistoryListSagas,
        ...transfersOrderSagas,
        ...walletLocksSagas,
        ...certificatesSagas,
        ...walletEncumbranceSagas,
        ...documentsSagas,
        ...qualifyStatementSagas,
        ...tariffSagas,
        ...paymentSchedulerSagas,
        ...earlyRepaymentSagas,
        ...dfaRatesSagas,
        ...taxOperationsSagas,
        ...taxTaxesSagas,
    }) as Saga[]
    yield* all(watchers.map((watcher) => fork(watcher)))
}

export default sagas
