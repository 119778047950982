import { ISecondaryOrderBuyAcceptRequest, ISecondaryOrderBuyRequest, ISecondaryOrderSellAcceptRequest, ISecondaryOrderSellRequest, unionTypeBackToFront } from '@dltru/dfa-models'
import { closeMessage, openMessage } from '@dltru/dfa-ui'
import { StrictEffect } from 'redux-saga/effects'
import { put, select, takeLatest } from 'typed-redux-saga'

import { authSelector } from '@store/auth/selectors'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'
import { signCustomTransaction } from '@store/helper'
import { updateOrderByOrderId } from '@store/orders/list'
import IAppState from '@store/states'

import api from '@services/api'

import { addSecondaryDfaById } from '../list'
import {
    postSecondaryBuyAcceptDfa,
    postSecondaryBuyAcceptDfaPrefly,
    postSecondaryBuyDfa,
    postSecondaryBuyDfaPrefly,
    postSecondarySellAcceptDfa,
    postSecondarySellAcceptDfaPrefly,
    postSecondarySellDfa,
    postSecondarySellDfaPrefly,
    revokeSecondaryBuyDfa,
    revokeSecondarySellDfa,
    updateSecondaryDfaDetails,
} from './index'
import {
    setItemsTransactionDetails,
    clearDataTransactionDetails,
    setIsLoadingTransactionDetails,
} from '@store/sign'
import { transactionDetailsSelectors } from '@store/sign/selectors'

function* handlePostSecondaryBuyDfaPrefly({ payload }: ReturnType<typeof postSecondaryBuyDfaPrefly>) {
    try {
        openMessage({
            type: 'loading',
            key: 'loadingPostOrderPrefly',
            message: 'Формирование транзакции',
        })

        yield* put(setIsLoadingTransactionDetails(true))
        
        const dfa = yield* select(dfaDetailsSelector.selectDfaDetails)
        const uuid = yield* select(authSelector.selectUserUid)
        // TODO REFACT DOUBLE CODE A.NOSALSKIY
        const secondaryDfa: Partial<ISecondaryOrderBuyRequest> = {
            asset_id: dfa.id,
            user_id: uuid,
            original_amount_dfa: payload?.original_amount_dfa || 0,
            price_per_dfa: (payload?.price_per_dfa || 0) * 100,
            payment_collect_type: payload?.payment_collect_type,
            skid: payload?.skid,
            buy_fee_amount: payload?.buy_fee_amount
        }

        const { data, error } = yield api.lib.postSecondaryDfaBuyOrderService(secondaryDfa)
        if (error) {
            throw error
        }

        yield* put(setItemsTransactionDetails(data))
        closeMessage('loadingPostOrderPrefly')
        yield* put(setIsLoadingTransactionDetails(false))
    } catch (error) {
        yield* put(setIsLoadingTransactionDetails(false))
        closeMessage('loadingPostOrderPrefly')
        openMessage({
            type: 'error',
            message: 'Ошибка формирования транзакции',
        })
        yield* put(updateSecondaryDfaDetails({ error: 'secondary dfa error', isLoading: false }))
    }
}

function* handlePostSecondaryBuyDfa({ payload }: ReturnType<typeof postSecondaryBuyDfa>) {
    try {
        openMessage({
            type: 'loading',
            key: 'loadingPostOrder',
            message: 'Размещение заявки',
        })
        yield* put(updateSecondaryDfaDetails({ error: '', isLoading: true }))

        const dfa = yield* select(dfaDetailsSelector.selectDfaDetails)
        const uuid = yield* select(authSelector.selectUserUid)


        const data = yield* select(transactionDetailsSelectors.selectItems)

        const secondaryDfa: Partial<ISecondaryOrderBuyRequest> = {
            asset_id: dfa.id,
            user_id: uuid,
            original_amount_dfa: payload?.original_amount_dfa || 0,
            price_per_dfa: (payload?.price_per_dfa || 0) * 100,
            payment_collect_type: payload?.payment_collect_type,
            skid: payload?.skid,
            buy_fee_amount: payload?.buy_fee_amount
        }

        if (data.transaction && payload?.skid) {
            const dataForSign = {
              ...secondaryDfa,
              transaction: data.transaction
            }
            yield* signCustomTransaction(
              payload.skid,
              dataForSign,
              api.lib.putSecondaryDfaBuyOrderService,
            )
          }
  

        // yield* put(updateOrderDetails({ error: '', isLoading: false, ...OrderBackToFront(data) }))
        if (data?.transaction) {
            yield* put(addSecondaryDfaById(unionTypeBackToFront(data.transaction)))
        }
        yield* put(clearDataTransactionDetails())
        yield* put(updateSecondaryDfaDetails({ error: '', isLoading: false, ...data }))

        closeMessage('loadingPostOrder')
        openMessage({
            type: 'success',
            message: 'Заявка размещена',
        })
    } catch (error) {
        closeMessage('loadingPostOrder')
        openMessage({
            type: 'error',
            message: 'Заявка отклонена',
        })
        yield* put(clearDataTransactionDetails())
        yield* put(updateSecondaryDfaDetails({ error: 'secondary dfa error', isLoading: false }))
    }
}

function* handlePostSecondarySellDfaPrefly({ payload }: ReturnType<typeof postSecondarySellDfa>) {
    try {
        openMessage({
            type: 'loading',
            key: 'loadingPostOrderPrefly',
            message: 'Формирование транзакции',
        })
        yield* put(setIsLoadingTransactionDetails(true))

        const dfa = yield* select(dfaDetailsSelector.selectDfaDetails)
        const { account } = yield* select((store: IAppState) => store.moneyAccounts)
        const uuid = yield* select(authSelector.selectUserUid)

        const secondaryDfa: Partial<ISecondaryOrderSellRequest> = {
            asset_id: dfa.id,
            user_id: uuid,
            original_amount_dfa: payload?.original_amount_dfa || 0,
            price_per_dfa: (payload?.price_per_dfa || 0) * 100,
            account_id: account?.id,
            payment_collect_type: payload?.payment_collect_type,
            skid: payload?.skid,
            sell_fee_amount: payload?.sell_fee_amount
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { data, error } = yield api.lib.postSecondaryDfaSellOrderService(secondaryDfa)
        if (error) {
            throw error
        }
        yield* put(setItemsTransactionDetails(data))
        closeMessage('loadingPostOrderPrefly')
        yield* put(setIsLoadingTransactionDetails(false))
    } catch (error) {
        yield* put(setIsLoadingTransactionDetails(false))
        closeMessage('loadingPostOrderPrefly')
        openMessage({
            type: 'error',
            message: 'Ошибка формирования транзакции',
        })
    }
}

function* handlePostSecondarySellDfa({ payload }: ReturnType<typeof postSecondarySellDfa>) {
    try {
        openMessage({
            type: 'loading',
            key: 'loadingPostOrder',
            message: 'Размещение заявки',
        })
        yield* put(updateSecondaryDfaDetails({ error: '', isLoading: true }))

        const dfa = yield* select(dfaDetailsSelector.selectDfaDetails)
        const { account } = yield* select((store: IAppState) => store.moneyAccounts)
        const uuid = yield* select(authSelector.selectUserUid)

        const data = yield* select(transactionDetailsSelectors.selectItems)

        const secondaryDfa: Partial<ISecondaryOrderSellRequest> = {
            asset_id: dfa.id,
            user_id: uuid,
            original_amount_dfa: payload?.original_amount_dfa || 0,
            price_per_dfa: (payload?.price_per_dfa || 0) * 100,
            account_id: account?.id,
            payment_collect_type: payload?.payment_collect_type,
            skid: payload?.skid,
            sell_fee_amount: payload?.sell_fee_amount
        }
       
        if (data.transaction && payload?.skid) {
            const dataForSign = {
              ...secondaryDfa,
              transaction: data.transaction
            }
            yield* signCustomTransaction(
              payload.skid,
              dataForSign,
              api.lib.putSecondaryDfaSellOrderService,
            )
          }

        // yield* put(updateOrderDetails({ error: '', isLoading: false, ...OrderBackToFront(data) }))
        if (data?.transaction) {
          yield* put(addSecondaryDfaById(unionTypeBackToFront(data.transaction)))
        }
        yield* put(updateSecondaryDfaDetails({ error: '', isLoading: false, ...data }))
        yield* put(clearDataTransactionDetails())

        closeMessage('loadingPostOrder')
        openMessage({
            type: 'success',
            message: 'Заявка размещена',
        })
    } catch (error) {
        yield* put(clearDataTransactionDetails())
        closeMessage('loadingPostOrder')
        openMessage({
            type: 'error',
            message: 'Заявка отклонена',
        })
        yield* put(updateSecondaryDfaDetails({ error: 'secondary dfa error', isLoading: false }))
    }
}

function* handlePostSecondaryBuyAcceptDfaPrefly({ payload }: any) {
    try {
        openMessage({
            type: 'loading',
            key: 'loadingPostOrderPrefly',
            message: 'Формирование транзакции',
        })
        yield* put(setIsLoadingTransactionDetails(true))

        const secondaryDfa: Partial<ISecondaryOrderBuyAcceptRequest> = {
            uuid: payload?.uuid,
            amount_dfa: payload?.amount_dfa || 0,
            skid: payload?.skid,
            buy_fee_amount: payload?.buy_fee_amount,
        }
        const { data, error } = yield api.lib.postSecondaryDfaBuyOrderAcceptService(
            payload?.uuid || '',
            secondaryDfa,
        )

        if (error) {
            throw error
        }
        yield* put(setItemsTransactionDetails(data))
        closeMessage('loadingPostOrderPrefly')
        yield* put(setIsLoadingTransactionDetails(false))
    } catch (error) {
        yield* put(setIsLoadingTransactionDetails(false))
        closeMessage('loadingPostOrderPrefly')
        openMessage({
            type: 'error',
            message: 'Ошибка формирования транзакции',
        })
    }
}

function* handlePostSecondaryBuyAcceptDfa({ payload }: any) {
    try {
        openMessage({
            type: 'loading',
            key: 'loadingPostOrder',
            message: 'Размещение заявки',
        })

        yield* put(updateSecondaryDfaDetails({ error: '', isLoading: true }))

        const data = yield* select(transactionDetailsSelectors.selectItems)

        const secondaryDfa: Partial<ISecondaryOrderBuyAcceptRequest> = {
            uuid: payload?.uuid,
            amount_dfa: payload?.amount_dfa || 0,
            skid: payload?.skid,
            buy_fee_amount: payload?.buy_fee_amount,
        }

        if (data.transaction && payload?.skid && payload.uuid) {
            const dataForSign = {
              ...secondaryDfa,
              transaction: data.transaction
            }
            yield* signCustomTransaction(
              payload.skid,
              dataForSign,
              api.lib.putSecondaryDfaBuyOrderAcceptService,
            )
          }
 
        yield* put(updateSecondaryDfaDetails({ error: '', isLoading: false, ...data.item }))
        yield* put(clearDataTransactionDetails())
        closeMessage('loadingPostOrder')
        openMessage({
            type: 'success',
            message: 'Заявка отправлена',
        })
    } catch (error) {
        yield* put(clearDataTransactionDetails())
        closeMessage('loadingPostOrder')
        openMessage({
            type: 'error',
            message: 'Заявка отклонена',
        })
        yield* put(updateSecondaryDfaDetails({ error: 'secondary dfa error', isLoading: false }))
    }
}

function* handlePostSecondarySellAcceptDfaPrefly({ payload }: any) {
    try {
        openMessage({
            type: 'loading',
            key: 'loadingPostOrderPrefly',
            message: 'Формирование транзакции',
        })
        yield* put(setIsLoadingTransactionDetails(true))

        const secondaryDfa: Partial<ISecondaryOrderSellAcceptRequest> = {
            uuid: payload?.uuid,
            amount_dfa: payload?.amount_dfa || 0,
            skid: payload?.skid,
            sell_fee_amount: payload?.sell_fee_amount,
            payment_collect_type: payload?.payment_collect_type
        }

        const { data, error } = yield api.lib.postSecondaryDfaSellOrderAcceptService(
            payload?.uuid || '',
            secondaryDfa,
        )

        if (error) {
            throw error
        }
        yield* put(setItemsTransactionDetails(data))
        closeMessage('loadingPostOrderPrefly')
        yield* put(setIsLoadingTransactionDetails(false))
    } catch (error) {
        yield* put(setIsLoadingTransactionDetails(false))
        closeMessage('loadingPostOrderPrefly')
        openMessage({
            type: 'error',
            message: 'Ошибка формирования транзакции',
        })
    }
}

function* handlePostSecondarySellAcceptDfa({ payload }: any) {
    try {
        openMessage({
            type: 'loading',
            key: 'loadingPostOrder',
            message: 'Размещение заявки',
        })

        yield* put(updateSecondaryDfaDetails({ error: '', isLoading: true }))

       const data = yield* select(transactionDetailsSelectors.selectItems)

       const secondaryDfa: Partial<ISecondaryOrderSellAcceptRequest> = {
            uuid: payload?.uuid,
            amount_dfa: payload?.amount_dfa || 0,
            skid: payload?.skid,
            sell_fee_amount: payload?.sell_fee_amount,
            payment_collect_type: payload?.payment_collect_type
        }

        if (data.transaction && payload?.skid) {
          const dataForSign = {
            ...secondaryDfa,
            transaction: data.transaction
          }
          yield* signCustomTransaction(
            payload.skid,
            dataForSign,
            api.lib.putSecondaryDfaSellOrderAcceptService,
          )
        }
        yield* put(updateSecondaryDfaDetails({ error: '', isLoading: false, ...data.item }))

        closeMessage('loadingPostOrder')
        openMessage({
            type: 'success',
            message: 'Заявка отправлена',
        })
    } catch (error) {
        closeMessage('loadingPostOrder')
        openMessage({
            type: 'error',
            message: 'Заявка отклонена',
        })
        yield* put(updateSecondaryDfaDetails({ error: 'secondary dfa error', isLoading: false }))
    }
}

function* handleBuyRevokeSecondary({ payload }: ReturnType<typeof revokeSecondaryBuyDfa>) {
    try {
        const { error, data } = yield api.lib.cancelSecondaryDfaBuyOrder(payload.data)

        if (error) {
            throw error
        }

        openMessage({
            type: 'success',
            message: 'Заявка отозвана',
        })
        if (payload.withUpdateOrder && data?.item) {
            yield put(updateOrderByOrderId(unionTypeBackToFront(data.item)))
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error)
    }
}

function* handleSellRevokeSecondary({ payload }: ReturnType<typeof revokeSecondarySellDfa>) {
    try {
        const { error, data } = yield api.lib.cancelSecondaryDfaSellOrder(payload.data)

        if (error) {
            throw error
        }

        openMessage({
            type: 'success',
            message: 'Заявка отозвана',
        })
        if (payload.withUpdateOrder && data?.item) {
            yield put(updateOrderByOrderId(unionTypeBackToFront(data.item)))
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error)
    }
}

export function* watchSecondaryDfa(): Generator<StrictEffect> {
    yield* takeLatest(postSecondaryBuyAcceptDfaPrefly.type, handlePostSecondaryBuyAcceptDfaPrefly)
    yield* takeLatest(postSecondaryBuyAcceptDfa.type, handlePostSecondaryBuyAcceptDfa)
    yield* takeLatest(postSecondarySellAcceptDfaPrefly.type, handlePostSecondarySellAcceptDfaPrefly)
    yield* takeLatest(postSecondarySellAcceptDfa.type, handlePostSecondarySellAcceptDfa)
    yield* takeLatest(postSecondarySellDfaPrefly.type, handlePostSecondarySellDfaPrefly)
    yield* takeLatest(postSecondarySellDfa.type, handlePostSecondarySellDfa)
    yield* takeLatest(postSecondaryBuyDfaPrefly.type, handlePostSecondaryBuyDfaPrefly)
    yield* takeLatest(postSecondaryBuyDfa.type, handlePostSecondaryBuyDfa)
    yield* takeLatest(revokeSecondaryBuyDfa.type, handleBuyRevokeSecondary)
    yield* takeLatest(revokeSecondarySellDfa.type, handleSellRevokeSecondary)
}
