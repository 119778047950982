import { ISecondaryOrder, SecondaryOrderStatus } from '@dltru/dfa-models'
import { StrictEffect } from 'redux-saga/effects'
import { put, takeLatest } from 'typed-redux-saga'

import api from '@services/api'

import { loadSecondaryDfas, loadSecondaryDfasByAssetId, updateSecondaryDfaList } from './index'
import { secondOrderBackToFront } from './mapper'

function* handleLoadSecondaryDfas() {
    try {
        yield* put(updateSecondaryDfaList({ error: '', isLoading: true }))

        const { data, error } = yield api.lib.getSecondaryDfaOrdersService()

        if (error) {
            throw error
        }

        let _data = (data?.items ?? []) as ISecondaryOrder[]
        _data = _data
            .filter((order) => [SecondaryOrderStatus.accepted].includes(order.status))
            .map((order) => secondOrderBackToFront(order))

        yield* put(updateSecondaryDfaList({ isLoading: false, data: _data }))
    } catch (error) {
        yield* put(updateSecondaryDfaList({ error, isLoading: false, data: [] }))
    }
}

function* handleLoadSecondaryDfasByAssetId({
    payload,
}: ReturnType<typeof loadSecondaryDfasByAssetId>) {
    try {
        yield* put(updateSecondaryDfaList({ error: '', isLoading: true }))

        const { data, error } = yield api.lib.getSecondaryDfaOrdersService()
        if (error) {
            throw error
        }

        let _data = (data?.items ?? []) as ISecondaryOrder[]
        _data = _data
            .filter((order) => {
                return ![
                    SecondaryOrderStatus.rejected,
                    SecondaryOrderStatus.executed,
                    SecondaryOrderStatus.canceled,
                    SecondaryOrderStatus.in_progress,
                ].includes(order.status)
            })
            .filter((order) => `${order.asset_id}` === `${payload}`)
            .map((order) => secondOrderBackToFront(order))

        yield* put(updateSecondaryDfaList({ isLoading: false, data: _data }))
    } catch (error) {
        yield* put(updateSecondaryDfaList({ error, isLoading: false, data: [] }))
    }
}

export function* watchLoadSecondaryDfas(): Generator<StrictEffect> {
    yield* takeLatest(loadSecondaryDfas.type, handleLoadSecondaryDfas)
}

export function* watchLoadSecondaryDfasByAssetId(): Generator<StrictEffect> {
    yield* takeLatest(loadSecondaryDfasByAssetId.type, handleLoadSecondaryDfasByAssetId)
}
